<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else class="my-4">
      <h4 class="text-center my-3">Привязка дисциплин студентов к дисциплинам иностранных ВУЗов</h4>

      <!-- list of students -->
      <div class="my-5">

        <div v-if="foreignDiscipline.students.length" class="mb-5">
          <div v-for="(student, studentIndex) in foreignDiscipline.students" :key="studentIndex" class="mt-4 mb-5">
            <h5 class="my-2">
              {{student?.barcode}} {{student?.fio}}
            </h5>

            <div class="my-2">
              <router-link :to="`/office-registration/grades-of-foreign-disciplines/${student.student_id}`"
                           target="_blank">
                Поставить оценки
              </router-link>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="my-3 fw-bold">Дисциплины FRONT - Семестр {{student?.semester}}</div>

                <div v-for="(frontDiscipline, frontDisciplineIndex) in student.front" :key="frontDisciplineIndex"
                     class="my-2 p-2 rounded-3"
                     :style="{backgroundColor: colorGroupedDisciplines[student.student_id][frontDiscipline.grouped_disciplines_id]||'#fff'}">
                  <input v-if="!frontDiscipline.grouped_disciplines_id"
                         class="form-check-input me-2" type="checkbox"
                         :value="{student_id: student.student_id, semester: student.semester, discipline_id: frontDiscipline.id}"
                         :disabled="frontDisciplinesIds.some(d=>d.student_id!==student.student_id)||foreignDisciplinesIds.some(d=>d.student_id!==student.student_id)"
                         v-model="frontDisciplinesIds">
                  <Button v-else icon="pi pi-times"
                          class="p-button-sm p-button-rounded p-button-danger p-button-text me-2"
                          @click="deleteDisciplineGroup(frontDiscipline.grouped_disciplines_id)"/>
                  <b>Кред. {{frontDiscipline.credit}}</b> {{frontDiscipline.name}}
                </div>
              </div>
              <div class="col-md-6">
                <div class="my-3 fw-bold">Дисциплины иностранных ВУЗов</div>

                <div v-for="(foreignDiscipline, foreignDisciplineIndex) in student.foreign"
                     :key="foreignDisciplineIndex"
                     class="my-2 p-2 rounded-3"
                     :style="{backgroundColor: colorGroupedDisciplines[student.student_id][foreignDiscipline.grouped_disciplines_id]||'#fff'}">
                  <input v-if="!foreignDiscipline.grouped_disciplines_id"
                         class="form-check-input me-2" type="checkbox"
                         :value="{student_id: student.student_id, discipline_id: foreignDiscipline.id}"
                         :disabled="frontDisciplinesIds.some(d=>d.student_id!==student.student_id)||foreignDisciplinesIds.some(d=>d.student_id!==student.student_id)"
                         v-model="foreignDisciplinesIds">
                  <Button v-else icon="pi pi-times"
                          class="p-button-sm p-button-rounded p-button-danger p-button-text me-2"
                          @click="deleteDisciplineGroup(foreignDiscipline.grouped_disciplines_id)"/>
                  <b>Кред. {{foreignDiscipline.credit}}</b> {{foreignDiscipline.name_ru}}
                </div>
              </div>
            </div>

            <hr v-if="studentIndex!==(foreignDiscipline.students.length-1)" class="hr-light-blue mt-2">

          </div>

          <!-- credit count section -->
          <div class="fixed-bottom" v-if="frontDisciplinesIds.length&&foreignDisciplinesIds.length">
            <div class="text-center p-2">
              <Button label="Привязать" @click="bindForeignDisciplines" class="p-button-rounded mx-2"
                      :disabled="bindBtnDisabled"/>
            </div>
          </div>
          <!-- end credit count section -->

        </div>

        <div v-else>
          Здесь пусто
        </div>

      </div>
      <!-- list of students -->


    </div>
  </div>
</template>


<script>

  import {mapGetters, mapActions, mapMutations, mapState} from 'vuex'

  export default {
    name: "BindingToDisciplinesOfForeignUniversities",
    data() {
      return {
        loading: true,
        doc_order_id: +this.$route.query.doc_order_id || 0,
        bindBtnDisabled: false,
        frontDisciplinesIds: [],
        foreignDisciplinesIds: [],
        colorGroupedDisciplines: {},
        colors: ['#C5CAE9', '#C8E6C9', '#FFE0B2', '#BBDEFB', '#FCE4EC', '#EF9A9A', '#B2DFDB', '#D7CCC8', '#FFCCBC', '#C5E1A5'],
      }
    },
    computed: {
      ...mapState('foreignDiscipline', ['foreignDiscipline']),
    },
    methods: {
      ...mapActions('foreignDiscipline', ['GET_FRONT_AND_FOREIGN_DISCIPLINES', 'POST_FOREIGN_FRONT_DISCIPLINE', 'DELETE_DISCIPLINE_GROUP']),
      async deleteDisciplineGroup(grouped_disciplines_id) {
        const {success} = await this.DELETE_DISCIPLINE_GROUP(grouped_disciplines_id)
        if (success) {
          await this.setData()
          this.$message({text: 'Связка дисциплин успешна удалена'})
          this.clearSelectedDisciplines()
        }
      },
      async bindForeignDisciplines() {

        if (this.frontDisciplinesIds.length === 1 || this.foreignDisciplinesIds.length === 1) {
          this.bindBtnDisabled = true

          let postData = []

          if (this.frontDisciplinesIds.length === 1) {
            postData = this.foreignDisciplinesIds.map(foreign => ({
              student_id: foreign.student_id,
              semester: this.frontDisciplinesIds[0].semester,
              front_discipline_id: this.frontDisciplinesIds[0].discipline_id,
              foreign_discipline_id: foreign.discipline_id
            }))
          } else if (this.foreignDisciplinesIds.length === 1) {
            postData = this.frontDisciplinesIds.map(front => ({
              student_id: front.student_id,
              semester: this.frontDisciplinesIds[0].semester,
              front_discipline_id: front.discipline_id,
              foreign_discipline_id: this.foreignDisciplinesIds[0].discipline_id
            }))
          }

          const {success} = await this.POST_FOREIGN_FRONT_DISCIPLINE(postData)
          if (success) {
            await this.setData()
            this.$message({text: 'Дисциплины успешно привязаны'})
            this.clearSelectedDisciplines()
          }

          this.bindBtnDisabled = false
          return
        }
        this.$error({text: 'Не верно выбраны дисциплины'})
      },
      clearSelectedDisciplines() {
        this.frontDisciplinesIds = []
        this.foreignDisciplinesIds = []
      },
      async setData() {
        if (this.doc_order_id) {
          await this.GET_FRONT_AND_FOREIGN_DISCIPLINES(this.doc_order_id)

          // set color for group discipline
          for (let s of this.foreignDiscipline.students) {
            this.colorGroupedDisciplines[s.student_id] = {}

            let uqFrontByGroup = s.front.filter(d => !!d.grouped_disciplines_id).reduce((acc, item) => {
              if (acc.map[item.grouped_disciplines_id]) {
                return acc
              }
              acc.map[item.grouped_disciplines_id] = true
              acc.uqArray.push(item)
              return acc
            }, {
              map: {},
              uqArray: []
            }).uqArray

            uqFrontByGroup.forEach((d, dIndex) => {
              this.colorGroupedDisciplines[s.student_id][d.grouped_disciplines_id] = this.colors[dIndex % 10]
            })
          }

        }
      }
    },
    async mounted() {
      await this.setData()
      this.loading = false
    }
  }
</script>

<style scoped>
  .hr-light-blue {
    color: #647eff;
    height: 2px;
  }
</style>